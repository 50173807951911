
.css-3yjvh5-MuiPaper-root-MuiAlert-root  {
    height: 100px;
    color: aqua;
    line-height: 30px;
}

.css-1pxa9xg-MuiAlert-message {
    font-size: 25px;
    font-weight: 500;
    margin: auto 65px auto 15px;
}

.css-1vooibu-MuiSvgIcon-root  {
    font-size: 65px;
    height: 3rem;
    width: 3rem;
    font-weight: 500;
    margin: auto 5px;
    line-height: 30px;
}
