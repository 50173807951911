* {
    margin: 0;
}

.main-log {
    width           : 100vw;
    height          : 100vh;
    background-color: #fff;
    display         : flex;
    flex-direction  : column;
    align-items     : center;
    box-sizing      : border-box;
    right           : 0;

    .header {
        position       : relative;
        width          : 90%;
        height         : 20%;
        display        : flex;
        flex-direction : row;
        justify-content: space-between;
        align-items    : center;

        .menu {
            display        : flex;
            flex-direction : row;
            margin-right   : 50px;
            width: 10vw;
            justify-content: space-between;
            align-items    : center;
        }
        
        .filter {
            position       : relative;
            display        : flex;
            flex-direction : row;
            justify-content: space-between;
            align-items    : center;
            min-width          : 70vw;
        
            .date-filter {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 38%;
                min-width: 40%;
                box-sizing: border-box;
                // background-color: aqua;

                .no-filter {
                    height: 25px;
                    width : 25px;
                    cursor: pointer;
                }

                .container-date {
                    display: flex;
                    flex-direction: column;
                    // background-color: burlywood;
                    width: 60%;

                    .box-date {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .picker-date {
                        width        : fit-content;
                        border-radius: 5px;
                        border       : solid 1px rgba(128, 128, 128, 0.389);
                        text-align   : center;
                        padding      : 3px 5px;
                        cursor       : pointer;
                        margin: 3px 0;
                        
                        &:focus {
                            outline: 0;
                        }
                    }
                }
            
                .search-icon {
                    height: 40px;
                    width: 30px;
                    cursor: pointer;
                }

                .SearchBtn {
                    background-color: transparent;
                    height: 7vh;
                    width: 20%;
                    border-radius: 5px;
                    border:  solid 1px #ee0000;
                    color: #ee0000;
                    cursor: pointer;

                    &:active {
                        background-color: #ee0000;
                        color: #fff;
                    }
                }
            }
        }
    }

    .table-log {
        width           : 90vw;
        height          : 70vh;
        box-sizing      : border-box;
        border-collapse : collapse;
        box-shadow      : 0px 0px 5px 1px gray;
        margin          : 0 auto;
        overflow-x      : hidden;
        overflow-y      : hidden;
        border-radius   : 10px;

        thead {
            background-color: #ee0000;
            height          : 10vh;
            border-radius   : 20px;
            font-weight     : 500;

            .head-title {
                border-radius   : 10px;
            }

            th {
                color: #fff;
                width: 70px;
            }
        }

         .container-table {
            width           : 90vw;
            height          : 60vh;
            box-sizing      : border-box;
            border-collapse : collapse;
            border-radius   : 10px;
            margin          : 0 auto;
            overflow-x      : hidden;
            overflow-y      : scroll;
            position: absolute;

            &::-webkit-scrollbar-thumb {
                background-color: #ee0000;
                border-radius   : 5px;
            }

            &::-webkit-scrollbar-button {
                border-radius: 20px;
            }

            &::-webkit-scrollbar {
                background-color: rgb(255, 255, 255);
                border-radius   : 0 0 10px 0;
                width           : 10px;
            }

                .logrow {
                    height    : 50px;
                    width: 100%;
                    display: table;
                    border-bottom: solid 0.5px rgba(131, 131, 131, 0.175);
                    position: relative;
                    
                    &:hover {
                        background-color: rgb(195, 195, 195);
                        border-radius   : 10px;
                    }

                    td {
                        border     : none;
                        font-weight: 100;
                        height     : 20px;
                        padding: 0 10px;
                        text-align: center;
                        width: 300px;
                        padding-top: 15px;
                    }
                }
            }
    } 

    }

.pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 7vh;
    width: fit-content;
    margin-top: 15px;

    li {
        margin: 15px 10px 0;
        list-style: none;
        height: 40px;
        width: 50%;
        border-radius: 5px;
        box-sizing: border-box;

        .page-link{
            border: solid 1px rgb(191, 191, 191);
            text-decoration: none;
            border-radius: 5px;
            padding: 15px 18px;
            font-weight: 500;
            background-color: #fff;

            /* &:focus{
                color: #ee0000;
            } */

            &:hover {
                cursor: pointer;
            }
        
        }
    }
}

.disabled {
    display: none;
}

.downloadBtn {
    height: 5vw;
    width: 30px;
    cursor: pointer;
    background-color: transparent;
}

.SearchIcon {
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.payment {
    width: 20%;
}

.selected > a {
    color: #ee0000;
}
