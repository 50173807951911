.container {
    display: flex;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('../../../public/image/login.jpg');
    background-size: cover;
    
    &::before{
        content: "";
        background-color: #ee0b1a48;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    .register {
        color: rgb(228, 228, 228);
        z-index: 2;
        font-size: 12px;
        margin-top: 10px;

        a{
            color: #ff606b;
            text-decoration: underline;

            &:hover{
                color: aliceblue;
            }
        }
    }

    .main-content {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        height: 45vh;
        width: 25vw;
        background-color: #fff;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
        
        &__logo {
            border-bottom: solid 1px gray;
            height: 40%;
            width: 85%;  
        } 

            form {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                margin-top: 5%;
        
                input {
                    width: 85%;
                    height: 25%;
                    border: solid 1px rgba(161, 161, 161, 0.927);
                    margin-bottom: 5px;
                    font-size: 18px;
                    box-sizing: border-box;
                    padding-left: 5%;
                    
                    &:hover {
                        border: solid 1px rgba(255, 0, 0, 0.203);
                    }
                
                    &:focus {
                        outline: 0;
                        border: solid 1px #ee0b19;
                    }
                }
                
                .button {
                    height: 30%;
                    width: 100%;
                    border-radius: 0 0 5px 5px;
                    background-color: #ee0b19;
                    color: #fff;
                    cursor: pointer;

                    &:active{
                        border: solid 1px black;
                        background-color: #87070f;
                    }
                    alert{
                        background-color: #ee0b19;
                    }
                }                     
            }
    }    
}
