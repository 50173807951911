.container-log {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.back {
  position: fixed;
  cursor: pointer;
  height: 30px;
  width: 30px;
  top: 30px;
  right: 40px;
}

.mainContent-log {
  // background-color: #20211e;
  width: 100%;
  padding: 30px 100px;
}

